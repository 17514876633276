import store from './index';
import waja from 'waja';

function defaultState () {
	return {
		hasUnrestrictedShipping: [],
		horizonEnabled: null,
		imiGoShippingMethodsMap: {},
		reSupplyPointId: 0,
		shippingStoresByPrioOrder: [],
		universalShippingMethodIds: [],
		// excludedShippingMethods: [], ////for later
	};
}

store.registerModule('shippingMethodRelatedInfo', {
	namespaced: true,
	state: defaultState(),
	actions: {
		getShippingMethodRelatedInfo({ commit }) {
			waja.get('shipping-method/related-info')
				.on('success', res => {
					commit('setShippingStoresByPrioOrder', res.data.shippingStoresByPrioOrder);
					commit('setUniversalShippingMethodIds', res.data.universalShippingMethodIds);
					commit('setImiGoShippingMethodsMap', res.data.imiDeliveryCodeTranslation);
					commit('setReSupplyPointId', res.data.supplyPoint);
					commit('setHorizonEnabled', res.data.horizonEnabled);
					commit('setHasUnrestrictedShipping', res.data.hasUnrestrictedShipping);
				}).go();
		},
		/* //for later
		getExcludedShippingMethods({ commit, dispatch }, { rows, testConstraints }) {
			console.log('vuex getExcludedShippingMethods');
			if (! state.imiGoShippingMethodsMap) {
				console.log('! state.imiGoShippingMethodsMap');
				dispatch('getShippingMethodRelatedInfo');
			}
			const excludedIds = Object.keys(this.imiGoShippingMethodsMap).filter((methodId) =>
				!(testConstraints ? testConstraints('shippingMethod', methodId * 1) : rows.product.possibleDeliveryMethods.includes(methodId)));

			console.log('vuex excludedIds 1 sort', excludedIds);
			excluded = [];
			for (const id in excludedIds) {
				excluded.push(this.imiGoShippingMethodsMap[excludedString[id]]);
			}
			for (const item of collection) { //kolla om vi kan constraina detta.. kolla has digital products!
				if (item.product.meta.excluded_shipping_methods) {
					for (const method of Object.values(item.product.meta.excluded_shipping_methods)) {
						excluded.push(this.imiGoShippingMethodsMap[parseInt(method)])
					}
				}
			}

			let measurement = 0;
			for (const row of rows) {
				const measure = row.product.packageMeasurements.reduce((acc, curr) => {
					return acc + curr
				});
				measurement += measure * (row.quantity ? row.quantity : 1);
			}

			if (this.packageMeasurement > 600) {
				excluded.push(this.imiGoShippingMethodsMap[31]);
			}
			commit('setExcludedShippingMethods', excluded);
		}*/
	},
	mutations: {
		setShippingStoresByPrioOrder (state, value) {
			state.shippingStoresByPrioOrder = value;
		},
		setUniversalShippingMethodIds (state, value) {
			state.universalShippingMethodIds = value;
		},
		setHorizonEnabled (state, value) {
			state.horizonEnabled = value;
		},
		setImiGoShippingMethodsMap (state, value) {
			state.imiGoShippingMethodsMap = value;
		},
		setReSupplyPointId (state, value) {
			state.reSupplyPointId = value;
		},
		setHasUnrestrictedShipping (state, value) {
			state.hasUnrestrictedShipping = value;
		},
		// setExcludedShippingMethods (state, value) { //for later
		// 	state.excludedShippingMethods = value;
		// },
	},
});
