import { inStockAnywhere } from 'utils';

export function generalDataLayer (isLoggedIn, cookies) {
	dataLayer.push({
		'user_info': {
			'is_logged_in': isLoggedIn,
		},
		'cookie_info': {
			'marketing_consent': cookies.marketing,
			'personalisation_consent': cookies.personalisation,
		}
	});
};

export function pageview (title, pageUrl, isLoggedIn = null, cookies = null) {
	const userInfo = {
		'is_logged_in': isLoggedIn,
	};

	const cookieInfo = {
		'marketing_consent': cookies?.marketing,
		'personalisation_consent': cookies?.personalisation,
	}

	dataLayer.push({
		'event': 'page_view',
		'page_info': {
			'page_url': pageUrl,
			'page_title': title
		},
		...(isLoggedIn !== null && { user_info: userInfo }),
		...(cookies !== null && (cookies?.marketing || cookies?.necessary || cookies?.personalisation) && { cookie_info: cookieInfo }),
	});
};

export function trackImpression (products, list, extraData = null, positionModifier = null) {
	const event = (extraData && extraData.event) || 'eec_impression';
	switch (list) {
		case 'category':
			list = 'Category Page';
			break;
		case 'product':
			list = 'Product Page';
			break;
		case 'checkout':
			list = 'Checkout';
			break;
		case 'cart':
			list = 'Cart';
			break;
		case 'confirmation':
			list = 'Confirmation Page';
			break;
		case 'campaign':
			list = 'Campaign Page';
			break;
		case 'article':
			list = 'Article Page';
			break;
		case 'manufacturer':
			list = 'Manufacturer Page';
			break;
		default:
			break;
	}

	const insuranceProducts = [];
	let itemListName;
	let itemListId;

	const productData = products.map((product, index) => {
		let position = index + 1;
		let clickImpressionData;

		if (positionModifier) {
			position = position + (positionModifier.productsPerPage * (positionModifier.page - 1));
		}

		if (event === 'eec_impression', event === 'select_item') {
			let specificPosition;
			if (extraData && extraData.isInsurance && extraData.insurance) {
				specificPosition = position;
			} else {
				specificPosition = extraData && extraData.position || extraData.position == 0 ? extraData.position : position;
			}

			itemListName = list ?? '';
			itemListId = list ? list.replaceAll(' ', '_').toLowerCase() : null;

			clickImpressionData = {
				position: specificPosition,
				...(list && { list: list }),
			}
		}

		/* Specific handling for product that is insurance */
		if (extraData && extraData.isInsurance && extraData.insurance) {
			return {
				name: extraData.insurance.name,
				id: extraData.insurance.id,
				price: extraData.insurance.price,
				brand: extraData.insurance.provider === 1 ? 'Webhallen' : '',
				// category: 'Trygghetsavtal/' + (product.categoryTree || ''),  // Save for next GA4 step
				category: 'Tjänster/Tjänster/Försäkringar',
				quantity: extraData.qty,
				...clickImpressionData,
			};
		}

		/* Specific handling for product that is gift card */
		if ((extraData && extraData.isGiftCard && extraData.giftCard) || (product.isGiftCard && product.giftCard)) {
			const giftCard = extraData.giftCard ?? product.giftCard;
			return {
				name: giftCard.name,
				id: giftCard.id,
				price: giftCard.price,
				brand: giftCard.brand,
				category: giftCard?.category ?? 'Presentkort/Presentkort/Presentkort', // Save for next GA4 step
				category: '',
				quantity: extraData.qty ?? product.qty,
				...clickImpressionData,
			};
		}

		let price = 0;
		let qty = 0;

		if (product.unitPrice) {
			price = product.unitPrice;
		} else {
			price = product.price ? product.price.price : 0;
		}

		if (extraData && extraData.event === 'eec_checkout') {
			qty = product.qty;
		}

		/**
		* The reason why this can't be handled in the previous insurance if-statement
		* is beacuse an insurance doesn't have it's own row but is instead part of a
		* product row so this ensures both the product and the insurance is added as
		* separate products for lists that requires it
		*/
		if (product.isInsured) {
			insuranceProducts.push({
				name: product.insurance.name,
				id: product.insurance.id,
				price: product.insurance.price,
				brand: product.insurance.provider ===  1 ? 'Webhallen' : '',
				// category: 'Trygghetsavtal/' + (product.categoryTree || ''), // Save for next GA4 step
				category: 'Tjänster/Tjänster/Försäkringar',
				quantity: product.qty || extraData.qty,
			});
		}

		const data = {
			id: product.id,
			name: product.name || 'N/A',
			category: product.categoryTree ? product.categoryTree : '',
			brand: product?.manufacturer?.name || product?.brand || 'N/A',
			price: price,
			...clickImpressionData,
			...(qty > 0 && {quantity: qty}),
		};

		if (extraData) {
			if(extraData.stock) {
				if(product.stock) {
					if (product.stock.web > 0) {
						data.dimension3 = 'yes';
						data.dimension4 = 'yes';
					} else {
						data.dimension3 = 'no';
						inStockAnywhere(product.stock) ? data.dimension4 = 'yes' : data.dimension4 = 'no';
					}
				} else {
					data.dimension3 = 'no';
					data.dimension4 = 'no';
				}
			}

			if (extraData.rating) {
				if (product.averageRating) {
					if (product.averageRating.ratingType === 'averageRating') {
						data.dimension5 = Math.round(product.averageRating.rating*2)/2; // round to nearest .5
					}
					if (product.averageRating.ratingType === 'hypefactor') {
						data.dimension6 = Math.round(product.averageRating.rating*2)/2; // round to nearest .5
					}
				}
				if (extraData?.showTopListReview !== false) {
					data.dimension7 = product.reviewHighlight || product.reviewHighlights ? 'yes' : 'no';
				}
			}

			if (extraData.alternativeCategory) {
				data.dimension8 = extraData.alternativeCategory;
			}

			if (extraData.qty) {
				data.quantity = extraData.qty;
			}

			if (product.qty) {
				data.quantity = product.qty;
			}

			if (extraData.campaignId) {
				data.dimension10 = extraData.campaignId;
			}
		}

		if (product?.regularPrice?.price) {
			data.metric1 = product.regularPrice.price;
		}

		if (extraData?.coupon) {
			if (parseInt(data.price) === parseInt(data.metric1)) {
				data.dimension16 = 'no_campaign';
			} else if (parseInt(data.price) < parseInt(data.metric1)) {
				data.dimension16 = product.price?.type ?? 'campaign';
			}
		}

		return data;
	});

	const allProductData = productData.concat(insuranceProducts);

	let ecommerce = {};

	switch (event) {
		case 'select_item':
			ecommerce = {
				item_list_id: itemListId,
				item_list_name: itemListName,
				items: allProductData,
			};
			break;
		case 'eec_detail':
			ecommerce = {
				detail: {
					actionField: {
						list: list,
					},
					products: allProductData,
				},
			};
			break;
		case 'add_to_cart':
			ecommerce = {
				currencyCode: 'SEK',
				add: {
					actionField: {
						list: list,
					},
					products: allProductData,
				},
			};
			break;
		case 'remove_from_cart':
			ecommerce = {
				currencyCode: 'SEK',
				remove: {
					actionField: {
						list: list,
					},
					products: allProductData,
				},
			};
			break;
		case 'eec_checkout':
			ecommerce = {
				checkout: {
					currencyCode: 'SEK',
					actionField: {
						step: 1,
						list: 'Checkout Page',
					},
					products: allProductData,
				},
			};
			break;
		case 'initiate_subscription':
			ecommerce = {
				currencyCode: 'SEK',
				click: {
					actionField: {
						list: list,
					},
					products: allProductData,
				},
			};
			break;
		case 'client_transaction':
			ecommerce = {
				currencyCode: 'SEK',
				transaction_id: extraData.orderId,
				value: extraData.totalSum,
				tax: extraData.tax,
				tax_shipping: extraData.taxShipping,
				shipping: extraData.shipping,
				payment_type: extraData.paymentType,
				shipping_type: extraData.shippingType,
				products: allProductData,
				...(extraData.coupon && { coupon: extraData.coupon }),
			};
			break;
		case 'eec_impression':
		default:
			ecommerce = {
				'currencyCode': 'SEK',
				'impressions': allProductData,
			};
			break;
	}

	dataLayer.push({
		event: event,
		ecommerce: ecommerce,
	});
};

export function trackEvent(event, category, action, label, isLoggedIn = null) {
	const userInfo = {
		is_logged_in: isLoggedIn,
	};

	dataLayer.push({
		'event': event,
		'target': category,
		'action': action,
		'target-properties': label,
		...(isLoggedIn !== null && { user_info: userInfo }),
	});
};


// Old impression
export function trackPromotionImpressionOld(highlights, loggedIn, source, type, position = '')  {
	const promotionData =  [];
	switch (source) {
		case 'section':
			source = 'Section';
			break;
		case 'index':
			source = 'Startpage';
			break;
		case 'campaign':
			source = 'Campaign Page';
			break;
		case 'product':
			source = 'Product Page';
			break;
		default:
			break;
	}

	switch (type) {
		case 'Banner':
		case 'Takeover':
			highlights.forEach(highlight => {
				let id = highlight.id;
				let name = highlight.name || highlight.description || '';
				if (highlight.campaign) {
					name = highlight.campaign.name;
					id = highlight.campaign.id;
				} else if ( highlight.product ) {
					name = highlight.product.name;
					id = highlight.product.id;
				}
				promotionData.push({
					id: id,
					name: name,
					creative: source + ' ' + type,
					position: 'Slot ' + (highlight.slot || '1'),
				});
			});
			break;
		case 'Tile':
			highlights.forEach((el, index) => {
				if (el.highlights) {
					if(el.highlights.length <= 6) {
						for (let i=0; i<6; i++) {
							let name = el.name || '';
							let id = el.highlights[i].highlightId;
							if (el.highlights[i].campaign) {
								name = el.highlights[i].campaign.name;
								id  = el.highlights[i].campaign.id;
							} else if ( el.highlights[i].product ) {
								name = el.highlights[i].product.name;
								id = el.highlights[i].product.id;
							}
							promotionData.push({
								id: id,
								name: name,
								creative: source + ' ' + type + ' ' + (index+1),
								position: position,
							});
						}
					} else {
						el.highlights.forEach((el, index) => {
							let id = el.id;
							let name = el.name || '';
							if (el.campaign) {
								name = el.campaign.name;
								id = el.campaign.id;
							} else if ( el.product ) {
								name = el.product.name;
								id = el.product.id;
							}
							promotionData.push({
								id: id,
								name: name,
								creative: source + ' ' + type,
								position: position,
							});
						});
					}
				} else {
					let id = el.id;
					let name = el.name || '';
					if (el.campaign) {
						name = el.campaign.name;
						id = el.campaign.id;
					} else if ( el.product ) {
						name = el.product.name;
						id = el.product.id;
					}
					promotionData.push({
						id: id,
						name: name,
						creative: source + ' ' + type,
						position: position,
					});
				}
			});
			break;
		case 'Popular Category':
			highlights.forEach((el, index) => {
				promotionData.push({
					id: el.category.id,
					name: el.category.name,
					creative: source + ' ' + type,
					position: 'Slot ' + (index+1),
				});
			});
			break;
		case 'Toplist':
		case 'Toplist Banner':
			highlights.forEach((el, index) => {
				promotionData.push({
					id: el.id,
					name: el.mainTitle,
					creative: source + ' ' + type,
					position: 'Slot ' + (index+1),
				});
			});

			break;
		default:
			break;
	}
	dataLayer.push({
		event: 'eec_promotionimpression',
		loggedIn: loggedIn ? 'yes': 'no',
		ecommerce: {
			promoView: {
				promotions: promotionData,
			},
		},
	});
};

// New impression + click
export function trackPromotionImpression (promotionData, promotionType, click = false) {
	const creativeSlot = promotionType === 'tile' ? promotionData.location + '_' + promotionType + '_' + promotionData.position : promotionData.location + '_' + promotionType;

	const data = {
		creative_name: promotionData.location + '_' + promotionData.type + '_' + promotionData.index,
		creative_slot: creativeSlot,
		promotion_id: promotionData.id,
		promotion_name: promotionData.name,
	};

	dataLayer.push({
		event: click ? 'select_promotion' : 'view_promotion',
		ecommerce: data,
	});
};

export function trackPromotionClick(id, name, source, type, position) {
	switch (source) {
		case 'section':
			source = 'Section';
			break;
		case 'index':
			source = 'Startpage';
			break;
		case 'campaign':
			source = 'Campaign Page';
			break;
		case 'product':
			source = 'Product Page';
			break;
		default:
			break;
	}
	const promotionData = [{
		id: id,
		name: name,
		creative: source + ' ' + type,
		position: 'Slot ' + position,
	}];

	dataLayer.push({
		event: 'eec_promotionclick',
		ecommerce: {
			promoClick: {
				promotions: promotionData,
			},
		},
	});
};

export function trackProductFilter (event) {
	dataLayer.push({
		event: event,
	});
};

export function trackCheckoutStep (step) {
	dataLayer.push({
		event: 'eec_checkout',
		ecommerce: {
			checkout: {
				actionField: {
					step: step,
				},
			},
		},
	});
};

/* Tracks selected option and checkout step when custom clicks an option */
export function selectCheckoutOption (step, selection, event = 'select_checkout_option') {
	dataLayer.push({
		event: event,
		step: step,
		selection: selection,
	});
};

/* Tracks the option selected when the customer continues to next step in checkout */
export function trackCheckoutOption (step, option) {
	dataLayer.push({
		event: 'checkout_option',
		ecommerce: {
			checkout_option: {
				actionField: {
					step: step,
					option: option,
				},
			},
		},
	});
};

export function trackCheckoutError (step, message) {
	dataLayer.push({
		event: 'checkout_error',
		step: step,
		error_message: message,
	});
};

// Temporary function to compare backend data to frontend data
export function testTransactionData(orderId) {
	let cookie = '';

	const regexSession = new RegExp(`(^| )${'_ga_PPSHD1E0FY'}=([^;]+)`)
	const matchSession = document.cookie.match(regexSession);
	if (matchSession) {
		cookie = matchSession[2];
	}
	const sessionCookie = cookie;
	const sessionId = cookie.split('.')[2];

	const regexClient = new RegExp(`(^| )${'_ga'}=([^;]+)`)
	const matchClient = document.cookie.match(regexClient);
	if (matchClient) {
		cookie = matchClient[2];
	}
	const clientCookie = cookie;
	const clientId = cookie.split('.').splice(2).join('.');

	const data = {
		orderId,
		sessionId,
		clientId,
		sessionCookie,
		clientCookie
	};

	dataLayer.push({
		event: 'checkout_vars',
		data: data,
	});
};